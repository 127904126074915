<template>
  <div>
    <div class="flex flex-col">
      <div class="flex items-center justify-end space-x-2">
        <base-search
          placeholder="Cari Kode"
          v-model="search"
          v-on:input="debounceSearch"
        />
      </div>
      <stockist-table
        @click-row="detailStockist"
        @change-page="onPageChangeStockist"
      />

      <office-detail-modal
        v-model="openDetail"
        @update="() => (openDetail = !openDetail)"
        read-only
      />

      <success-modal
        v-model="success"
        @close="() => (success = !success)"
        @previous="() => (success = !success)"
      />

      <failed-modal
        v-model="failed"
        :errors="getError"
        @close="() => (failed = !failed)"
        @previous="() => (failed = !failed)"
      />
    </div>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import BaseSearch from '@/components/base/Search.vue';
import FailedModal from '@/components/modal/failed-modal.vue';
import SuccessModal from '@/components/modal/success-modal.vue';
import OfficeDetailModal from '@/components/office/office-detail-modal-2.vue';
import { getRelationships } from '@/services/utils.service';
import StockistTable from '@/components/stockist/stockist-table.vue';

export default {
  name: 'Home',
  components: {
    BaseSearch,
    // BaseButton,
    FailedModal,
    SuccessModal,
    OfficeDetailModal,
    StockistTable,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      modalType: '',
      selectedOffice: {},
      type: '',
      search: null,
      searchStockistDetail: '',
      anyStockist: false,
      openListStockist: false,
      openAdd: false,
      openDetail: false,
      success: false,
      failed: false,
      stockist_office_category: null,
      promote: {
        code: null,
        email: null,
        no_account_gl: null,
        cost_center: null,
      },
      dataDetailStockist: {
        data: [],
        meta: {
          page: {},
        },
      },
      listStockist: {
        data: [],
        meta: {
          page: {
            total: '',
            perPage: '',
            currentPage: '',
          },
        },
      },
      dataStockist: {
        id: '',
        kode_stockist: '',
        nama_stockist: '',
        phone: '',
        email: '',
        alamat: '',
        users: {
          data: [],
          meta: {
            page: {
              total: '',
              perPage: '',
              currentPage: '',
            },
          },
        },
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
          area: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        area: null,
      },
    };
  },
  created() {
    this.loadOffices(this.officeParams).then((response) => {
      this.listStockist = response.data;
    });
    this.stockist_office_category = this.getAvailableOfficeCategories.filter(
      function (el) {
        return el.attributes.name === 'Stockist';
      }
    );

    const handleEscape = () => {
      this.openDetail =
        this.openListStockist =
        this.success =
        this.failed =
        this.openAdd =
          false;
      this.search = '';
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
      getOffice: 'offices/getOffice',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getError: 'stockists/getError',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
    }),
    officeParams() {
      return {
        'filter[search]': this.search ? this.search : undefined,
        'filter[office_category_id]': 4,
        'fields[simple-offices]': 'code,name,temp_code,office,office_id',
        include: 'office',
      };
    },
  },
  methods: {
    getRelationships,
    ...mapActions({
      fetchUsersByOffice: 'users/fetchUsersByOffice',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchOfficeById: 'offices/fetchOfficeById',
      createStockist: 'stockists/createStockist',
      updateAddress: 'addresses/updateAddress',
      promoteToSC: 'offices/promoteToSC',
      updateOffice: 'offices/updateOffice',
      updateUser: 'users/updateUser',
      fetchOffices: 'offices/fetchOffices',
    }),
    loadOffices({ ...params } = {}) {
      return this.fetchOffices({
        ...this.officeParams,
        ...params,
      });
    },
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    openAddStockist() {
      this.openAdd = !this.openAdd;
      this.fetchProvinces({
        pageNumber: 1,
      });
      this.clearDataStockist();
    },
    openModal() {
      this.openListStockist = !this.openListStockist;
      this.loadOffices().then((response) => {
        this.dataDetailStockist = response.data;
      });
    },
    closeModal() {
      this.success = this.failed = false;
      this.clearDataStockist();
      this.openAdd = !this.openAdd;
      this.loadOffices();
    },
    addMoreStockist() {
      this.success = !this.success;
      this.clearDataStockist();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearDataStockist();
    },
    debounceSearch: function () {
      this.loadOffices();
    },
    debounceSearchDetail: debounce(function () {
      this.loadOffices({
        pageNumber: 1,
        pageSize: 5,
        search: this.searchStockistDetail,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    }, 300),
    async onPageChangeStockist(page) {
      let response = {};
      response = await this.loadOffices(page);
      this.listStockist = response.data;
    },
    onPageChangeDetailStockist(page) {
      this.loadOffices({
        pageNumber: page,
        pageSize: 5,
        search: this.searchStockistDetail,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    },
    async detailStockist(office) {
      office = getRelationships(
        this.getOffices,
        office.relationships.office.data.id
      );
      this.dataStockist.id = office.id;
      await Promise.all([
        this.fetchOfficeById({
          office_id: office.id,
          params: {
            include: 'addresses,area',
          },
        }),
        this.fetchUsersByOffice({
          officeId: office.id,
        }),
      ]);
      this.openDetail = !this.openDetail;
    },
    changeStockist(data) {
      this.dataStockist.kode_stockist = data.attributes.code;
      this.searchStockist();
      this.searchStockistDetail = '';
      this.openListStockist = !this.openListStockist;
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataStockist.provinsi.id,
        pageNumber: 1,
      });
      this.dataStockist.kota =
        this.dataStockist.kecamatan =
        this.dataStockist.desa =
          {
            id: '',
            name: '',
          };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataStockist.kota.id,
        pageNumber: 1,
      });
      this.dataStockist.kecamatan = this.dataStockist.desa = {
        id: '',
        name: '',
      };
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataStockist.kecamatan.id,
        pageNumber: 1,
      });
      this.dataStockist.desa = {
        id: '',
        name: '',
      };
    },
    getAddresses(id) {
      let address = this.getOffices.included.filter(function (el) {
        return el.id === id;
      });
      return address[0];
    },
    getArea(areaId) {
      return getRelationships(this.getOffices, areaId);
    },
    searchStockist() {
      if (this.dataStockist.kode_stockist) {
        this.loadOffices({
          keyword: this.dataStockist.kode_stockist,
        }).then(() => {
          if (this.getOffices.data.length > 0) {
            this.anyStockist = true;
            this.dataStockist.id = this.getOffices.data[0].id;
            this.dataStockist.kode_stockist =
              this.getOffices.data[0].attributes.code;
            this.dataStockist.nama_stockist =
              this.getOffices.data[0].attributes.name;
            this.dataStockist.phone = this.getOffices.data[0].attributes.phone;
            this.dataStockist.email = this.getOffices.data[0].attributes.email;
            this.dataStockist.alamat =
              this.getOffices.data[0].attributes.address;
            const addresses = this.getAddresses(
              this.getOffices.data[0].relationships.addresses.data[0].id
            );
            this.dataStockist.provinsi.id = addresses.attributes.province_id;
            this.dataStockist.provinsi.name =
              addresses.attributes.province_name;
            this.changeProvince();
            this.dataStockist.kota.id = addresses.attributes.city_id;
            this.dataStockist.kota.name = addresses.attributes.city_name;
            this.changeCity();
            this.dataStockist.kecamatan.id = addresses.attributes.district_id;
            this.dataStockist.kecamatan.name =
              addresses.attributes.district_name;
            this.changeDistrict();
            this.dataStockist.desa.id = addresses.attributes.village_id;
            this.dataStockist.desa.name = addresses.attributes.village_name;
          } else {
            this.anyStockist = false;
            this.dataStockist = {
              id: '',
              kode_stockist: this.dataStockist.kode_stockist,
              nama_stockist: '',
              phone: '',
              email: '',
              alamat: '',
              provinsi: {
                id: '',
                name: '',
              },
              kota: {
                id: '',
                name: '',
              },
              kecamatan: {
                id: '',
                name: '',
              },
              desa: {
                id: '',
                name: '',
              },
              area: null,
            };
          }
        });
      }
    },
    addStockist: function () {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            office_type: 'stockist',
            code: this.dataStockist.kode_stockist,
            name: this.dataStockist.nama_stockist,
            pasif: null,
            urut: null,
            address: this.dataStockist.alamat,
            address2: null,
            area: this.dataStockist.kota.area + '',
            kta: null,
            phone: this.dataStockist.phone,
            email: this.dataStockist.email,
            pulau: null,
            address_details: [
              {
                province_id: parseInt(this.dataStockist.provinsi.id),
                city_id: parseInt(this.dataStockist.kota.id),
                district_id: parseInt(this.dataStockist.kecamatan.id),
                village_id: parseInt(this.dataStockist.desa.id),
              },
            ],
          },
          relationships: {
            'office-category': {
              data: {
                type: 'office-categories',
                id: this.stockist_office_category[0].id,
              },
            },
          },
        },
      };
      if (!this.anyStockist) {
        this.createStockist(payload).then((response) => {
          if (response) {
            this.success = true;
            this.loadOffices({
              pageNumber: 1,
              pageSize: 5,
            }).then((response) => {
              this.listStockist = response.data;
            });
          } else {
            this.failed = true;
          }
        });
      } else {
        payload.data.id = this.dataStockist.id;
        this.updateOffice(payload).then((response) => {
          if (response) {
            this.loadOffices({
              pageNumber: 1,
              pageSize: 5,
            }).then((response) => {
              this.listStockist = response.data;
            });
            const payloadAddress = {
              data: {
                type: 'addresses',
                id: response.data.data.relationships['addresses'].data[0].id,
                attributes: {
                  detail: 'test',
                },
                relationships: {
                  office: {
                    data: {
                      type: 'offices',
                      id: this.dataStockist.id,
                    },
                  },
                  province: {
                    data: {
                      type: 'provinces',
                      id: this.dataStockist.provinsi.id,
                    },
                  },
                  city: {
                    data: {
                      type: 'cities',
                      id: this.dataStockist.kota.id,
                    },
                  },
                  district: {
                    data: {
                      type: 'districts',
                      id: this.dataStockist.kecamatan.id,
                    },
                  },
                  village: {
                    data: {
                      type: 'villages',
                      id: this.dataStockist.desa.id,
                    },
                  },
                },
              },
            };
            this.updateAddress(payloadAddress).then((response) => {
              if (response) {
                this.success = true;
              }
            });
          } else {
            this.failed = true;
          }
        });
      }
    },
    clearDataStockist() {
      this.dataStockist = {
        kode_stockist: '',
        nama_stockist: '',
        phone: '',
        email: '',
        alamat: '',
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        area: null,
      };
    },
    promoteStockistToSC() {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            code: this.promote.code,
            email: this.promote.email,
            no_account_gl: this.promote.no_account_gl,
            cost_center: this.promote.cost_center,
          },
        },
      };
      this.promoteToSC({
        payload,
        id: this.selectedOffice.id,
      }).then((response) => {
        if (response) {
          this.modalType = null;
          this.loadOffices({
            pageNumber: 1,
            pageSize: 5,
            office_type: this.office_type,
          });
        } else {
          this.modal = 'error';
        }
      });
    },
    openPromote(data, type) {
      this.modalType = type;
      this.isModalOpen = true;
      this.selectedOffice = data;
    },
  },
};
</script>
